<template>
    <div>
        <div class="title color-white" :style="titleStyle">
            <h4>{{ $t('subscribe.title1') }}</h4>
            <p class="font-24" v-html="$t('subscribe.title2')" />
        </div>
        <el-card class="content-box bg-white my-20">
            <div class=" d-flex-center">
                <h3 class="mr-auto my-0" v-text="$t('subscribe.title')" />
                <el-badge :value="coinList.subscribed_orders" :hidden="coinList.subscribed_orders <= 0" class="item">
                    <el-button class="ml-20" type="primary" icon="el-icon-loading" plain @click="$nav.push('/subscribe/log')">{{ $t('subscribe.ms') }}</el-button>
                </el-badge>
                <el-badge :value="coinList.positions_orders" :hidden="coinList.positions_orders <= 0" class="item">
                    <el-button class="ml-20" type="primary" icon="el-icon-coin" plain @click="$nav.push('/subscribe/positions')">{{ $t('subscribe.mp') }}</el-button>
                </el-badge>
            </div>
        </el-card>
        <div class="content-box pb-50">
            <el-card v-for="item in coinList.data" :key="item.id" class="mb-15">
                <el-row :gutter="10" type="flex" justify="center" align="middle" class="color-666 py-15 font-14">
                    <el-col :span='4'>Logo / {{ $t('name') }}</el-col>
                    <el-col :span='4'>{{ $t('subscribe.issue_date') }}</el-col>
                    <el-col :span='4'>{{ $t('subscribe.issue_quota') }}</el-col>
                    <el-col :span='4'>{{ $t('subscribe.current_price') }}</el-col>
                    <el-col :span='4' class="text-right">{{ $t('status') }}</el-col>
                    <el-col :span='4' class="text-right">{{ $t('action') }}</el-col>
                </el-row>
                <el-row :gutter="10" type="flex" justify="center" align="middle" class="color-333 py-15 border-top font-16">
                    <el-col :span='4'>
                        <div class="d-flex-center">
                            <el-avatar class="mr-20" shape="square" :size="40" :src="item.logo" />
                            <span class="text-center my-10 mr-auto font-18">{{ item.coin }}</span>
                        </div>
                        
                    </el-col>
                    <el-col :span='4'>
                        <span>{{ item.release_date }}</span>
                    </el-col>
                    <el-col :span='4'>
                        <span>{{ item.num }}</span>
                    </el-col>
                    <el-col :span='4'>
                        <span>{{ item.now_price }} USDT</span>
                    </el-col>
                    <el-col :span='4' class="text-right">
                        <!-- <el-tag type="warning" icon="el-icon-info" plain class="ml-auto" native-type="button" @click="$nav.push('details/'+item.coin)">Details</el-tag> -->
                        <el-tag v-if="item.status == 0" size="medium" effect='plain' class="scale-150">{{ $t('subscribe.wtb') }}</el-tag>
                        <el-tag v-else-if="item.status == 1" size="medium" effect='plain' class="scale-150">{{ $t('subscribe.Subscring') }}</el-tag>
                        <el-tag v-else-if="item.status == 2" size="medium" effect='plain' class="scale-150">{{ $t('subscribe.wtm') }}</el-tag>
                        <el-tag v-else-if="item.status == 3" size="medium" effect='plain' class="scale-150">{{ $t('subscribe.on_market') }}</el-tag>
                        <el-tag v-else>{{ $t('subscribe.has_ended') }}</el-tag>
                    </el-col>
                    <el-col :span='4' class="text-right">
                        <el-button type="warning" icon="el-icon-info" plain class="ml-auto" native-type="button" @click="$nav.push('details/'+item.coin)">{{ $t('details') }}</el-button>
                    </el-col>
                </el-row>
            </el-card>
        </div>
    </div>
</template>

<script>
import iocBanner from '@/assets/ioc/banner.jpg'
export default {
    data() {
        return {
            titleStyle: {
                backgroundImage: 'url(\''+iocBanner+'\')'
            },
            coinList: {
                data: [],
                open_coin_subscribe: 0,
                subscribed_orders: 0,
                positions_orders: 0
            },
            loading: false
        }
    },
    created: function () {
        this.fatchData()
    },
    methods: {
        fatchData: async function (){
            this.loading = true
            const { data } = await this.$request.post('coin')
            this.coinList = data
            this.loading = false
        }
    }
}
</script>

<style lang="scss" scoped>
.title{
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
    height: 280px;
    h4,
    p{
        margin: 0;
        text-align: center;
    }
    h4{
        font-size: 36px;
        padding-top: 70px;
    }
    p{
        font-size: 18px;
        padding-top: 20px;
        line-height: 30px;
    }
}
.data-list{
    color: #333;
    .data-item:hover{
        box-shadow: #409EFF 0 0 5px 1px;
    }
}
.scale-150{
    transform: scale(1.3);
    margin-right: 10px;
}
</style>